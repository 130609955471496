import React from 'react';
import {Link} from "react-router-dom";


const Footer = React.forwardRef((props, ref) => {
    return (
        <footer className="footer footer-transparent d-print-none">
            <div className="container-xl">
                <div className="row text-center align-items-center flex-row-reverse">
                    <div className="col-lg-auto ms-lg-auto">
                        <ul className="list-inline list-inline-dots mb-0">
                            <li className="list-inline-item">
                                <Link to="/docs" className="link-secondary"  rel="noopener">Documentation</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-lg-auto mt-3 mt-lg-0">
                        <ul className="list-inline list-inline-dots mb-0">
                            <li className="list-inline-item">
                                Copyright © 2024
                                <a href="." className="link-secondary"> Ringer</a>.
                                All rights reserved.
                            </li>
                            <li className="list-inline-item">
                                <a href="./changelog.html" className="link-secondary" rel="noopener">
                                    v0.1.0-alpha
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default Footer;