import React from 'react';
import {Link} from "react-router-dom";

const ControllersTableItem = React.forwardRef((props, ref) => {

    return (
        <tr>
            <td className="text-reset">{props.controller.serial}</td>
            {/*<td className="text-secondary text-capitalize ">{props.controller.model}</td>*/}
            <td className="text-secondary text-center">{props.controller.channels}</td>
            <td className="text-secondary">
                <span className="text-capitalize text-center">{props.controller.status === "online" ?

                    <span className="text-green"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         className="icon icon-tabler icons-tabler-outline icon-tabler-wifi alert-icon">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 18l.01 0"/>
                        <path d="M9.172 15.172a4 4 0 0 1 5.656 0"/>
                        <path d="M6.343 12.343a8 8 0 0 1 11.314 0"/>
                        <path d="M3.515 9.515c4.686 -4.687 12.284 -4.687 17 0"/>
                    </svg>
                    </span>:
                    <span className="text-gray-900">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         className="icon icon-tabler icons-tabler-outline icon-tabler-wifi-off alert-icon">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 18l.01 0"/>
                        <path d="M9.172 15.172a4 4 0 0 1 5.656 0"/>
                        <path d="M6.343 12.343a7.963 7.963 0 0 1 3.864 -2.14m4.163 .155a7.965 7.965 0 0 1 3.287 2"/>
                        <path d="M3.515 9.515a12 12 0 0 1 3.544 -2.455m3.101 -.92a12 12 0 0 1 10.325 3.374"/>
                        <path d="M3 3l18 18"/>
                    </svg>
                        </span>
                    // <span className="badge bg-green text-green-fg">Online</span> :
                    // <span className="badge bg-gray-700 text-gray">Offline</span>
                }</span>
            </td>
            {/*<td className="text-secondary">{props.controller.version}</td>*/}
            <td className="w-1">
                <span className="disabled">
                    {props.controller.status === "online" ?
                <Link to={"controllers/" + props.controller.serial} disabled >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="icon icon-tabler icons-tabler-outline icon-tabler-device-remote">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                        <path d="M7 3m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z"/>
                        <path d="M12 3v2"/>
                        <path d="M10 15v.01"/>
                        <path d="M10 18v.01"/>
                        <path d="M14 18v.01"/>
                        <path d="M14 15v.01"/>
                    </svg>
                </Link> :

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-device-remote"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M12 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                            <path d="M7 3m0 2a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2z"/>
                            <path d="M12 3v2"/>
                            <path d="M10 15v.01"/>
                            <path d="M10 18v.01"/>
                            <path d="M14 18v.01"/>
                            <path d="M14 15v.01"/>
                        </svg> }
                </span>
            </td>
        </tr>
    );
});

export default ControllersTableItem;