import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import Footer from "../components/UI/Footer";
import "../app.css";
import {Store} from "react-notifications-component";
const ControlIdPage = React.forwardRef((props, ref) => {
    const {sn} = useParams()
    const [ringings, setRingings] = useState({zvons: []})
    const [isLoading, setLoading] = useState(true);

    const token = localStorage.getItem('token');

    useEffect(() => {
        axios.get(`http://api.ringer.fun/v1/` + sn + '/ringing', {headers: {Authorization: `Bearer ${token}`}},)
            .then(res => {
                console.log(res);
                console.log(res.data);

                if (res.status === 200) {
                    setRingings(res.data);
                }
                setLoading(false);
            }).catch(err => console.log(err))
    }, [sn, token])

    const handleStop = () => {


        axios.post(`http://api.ringer.fun/v1/` + sn + '/stop/false', {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        },)
            .then(res => {
                console.log(res);
                console.log(res.data);

                Store.addNotification({
                    title: "Success!",
                    message: "",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }).catch(err => {
            Store.addNotification({
                title: "Failed!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
                console.log(err)
            })

    }

    const handlePlay = (id) => {

        axios(
            {
                method: "POST",
                url: `http://api.ringer.fun/v1/` + sn + '/play/' + id,
                data: {},
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then(res => {
            console.log(res);
            console.log(res.data);
            Store.addNotification({
                title: "Success!",
                message: "Playing " + id,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });

        }).catch(err => {
            Store.addNotification({
                title: "Failed!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            console.log(err)
        })

    }

    return (
        <div className="page-wrapper">
            <div className="btn-stop">
                <Link className="btn btn-danger btn-icon "
                      onClick={handleStop} to={"#"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                         fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-player-stop">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M17 4h-10a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3 -3v-10a3 3 0 0 0 -3 -3z"/>
                    </svg>
                </Link>
            </div>
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                {sn}
                            </div>
                            <h2 className="page-title">
                                Remote control
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-body">
                <div className="container-xl">
                    {/*<div className="alert alert-info bg-light" role="alert">*/}
                    {/*    <div className="d-flex">*/}
                    {/*        <div>*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" className="icon alert-icon" width="24"*/}
                    {/*                 height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"*/}
                    {/*                 strokeLinecap="round" strokeLinejoin="round">*/}
                    {/*                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>*/}
                    {/*                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>*/}
                    {/*                <path d="M12 9h.01"></path>*/}
                    {/*                <path d="M11 12h1v4h1"></path>*/}
                    {/*            </svg>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            To play a ringing, click on the ringing number or on the <svg*/}
                    {/*            xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
                    {/*            viewBox="0 0 24 24" fill="none" stroke="currentColor"*/}
                    {/*            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*            className="icon icon-tabler icons-tabler-outline icon-tabler-player-play">*/}
                    {/*            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>*/}
                    {/*            <path d="M7 4v16l13 -8z"/>*/}
                    {/*        </svg> sign next to the ringing name.*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row row-deck row-cards">
                        <div className="col-md-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="icon icon-tabler icons-tabler-outline icon-tabler-playlist">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M14 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
                                            <path d="M17 17v-13h4"/>
                                            <path d="M13 5h-10"/>
                                            <path d="M3 9l10 0"/>
                                            <path d="M9 13h-6"/>
                                        </svg>
                                        List of melodies
                                    </h4>
                                </div>
                                <div className="card-table table-responsive">
                                    {/*{ isLoading ? "loading" : "loaded"}*/}
                                    <table className="table card-table table-vcenter datatable ">
                                        <thead className="text-sm-center">
                                        <tr>
                                            <th className="w-1">#</th>
                                            <th>Name</th>
                                            <th className="w-1">Channels</th>
                                            <th className="w-1">Length</th>
                                            <th className="w-1">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {ringings.zvons.sort(function (a, b) {
                                                return parseInt(a.id) - parseInt(b.id)
                                            }
                                        ).map(ringing =>
                                            <tr key={ringing.id}>
                                                <td className="w-1">
                                                    <Link className="btn btn-icon bg-green text-white " onClick={() => {
                                                        handlePlay(ringing.id)
                                                    }} to="#">
                                                        {ringing.id}
                                                    </Link>
                                                </td>
                                                <td>{ringing.name}</td>
                                                <td className="w-1 text-center">{ringing.channels}</td>
                                                <td>{ringing.length}</td>
                                                <td><Link to="#" onClick={() => handlePlay(ringing.id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                         className="icon icon-tabler icons-tabler-outline icon-tabler-player-play">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M7 4v16l13 -8z"/>
                                                    </svg>
                                                </Link>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
});

export default ControlIdPage;