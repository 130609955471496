import "@tabler/core/dist/css/tabler.css"
import LoginPage from "./pages/LoginPage";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Navbar from "./components/UI/NavBar";
import AboutPage from "./pages/AboutPage";
import "@tabler/core/dist/js/tabler"
import HomePage from "./pages/HomePage";
import ControlPage from "./pages/ControlPage";
import ProductsPage from "./pages/ProductsPage";
import HelpPage from "./pages/HelpPage";
import React, {useEffect, useState} from 'react';
import {AuthContext} from "./utils/Context";
import ControlIdPage from "./pages/ControIdlPage";
import "./app.css"
//import "@tabler/core/dist/js/tabler.min"
import 'react-notifications-component/dist/theme.css';
import {ReactNotifications} from "react-notifications-component";

function App() {
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setLoading] = useState(true);

    console.log(isAuth);

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            setIsAuth(true)
        }
        setLoading(false);
    }, [])

    return (
        <>
            <ReactNotifications />

        <AuthContext.Provider value={{
            isAuth,
            setIsAuth,
            isLoading
        }}>

        <BrowserRouter>
            {
                isAuth ?  <Navbar/> : <></>
            }
                    <Routes>
                        <Route element={isAuth ? <HomePage/> : <Navigate to="/login" replace />} path="/"/>
                        {/*<Route element={isAuth ? <HomePage/> : <Navigate to="/login" replace />} path="/home"/>*/}
                        <Route element={<ProductsPage replace/>} path="/products"/>
                        <Route element={<ControlPage/>} path="/controllers"/>
                        <Route element={<ControlIdPage/>} path="/controllers/:sn"/>
                        <Route element={<AboutPage/>} path="/about"/>
                        <Route element={<HelpPage/>} path="/help"/>
                        <Route element={isAuth ? <Navigate to="/"/> : <LoginPage/>} path="/login"/>

                        <Route path='/*' element={isAuth ? <Navigate to="/home" replace/> : <Navigate to='/login' replace/>}/>

                </Routes>

        </BrowserRouter>
        </AuthContext.Provider></>
    )
        ;
}

export default App;
