import {createContext} from 'react'

function identity(n) {
    return n;
}

export const AuthContext = createContext({
    isAuth: false,
    isLoading: true,
    setIsAuth: identity,
});
