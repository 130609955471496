import React, {useEffect, useState} from 'react';
import ControllersTableItem from "./ControllersTableItem";
import axios from "axios";


const ControllersListTable = React.forwardRef((props, ref) => {

    const [controllers, setControllers] = useState({controllers:[{serial:"", model:"", channels: 0, status: "", version: ""}]})
    const [ setLoading] = useState(true);

    const token = localStorage.getItem('token');

    useEffect(() => {
        axios.get(`http://api.ringer.fun/v1/controllers`, {headers: { Authorization: `Bearer ${token}` }}, )
            .then(res => {
                console.log(res);
                console.log(res.data);

                if(res.status === 200){
                 setControllers(res.data);
                }
                setLoading(false);
            }).catch(err => console.log(err))
    }, [token, setLoading])

    return (
        <table className="table card-table table-vcenter datatable">
            <thead>
            <tr>
                <th className="">Serial</th>
                {/*<th className="w-1">Model</th>*/}
                <th className="w-1">Channels</th>
                <th className="w-1">Status</th>
                {/*<th className="w-1">Version</th>*/}
                <th className="w-1"></th>
            </tr>
            </thead>
            <tbody>
            {controllers.controllers.map(controller =>
                <ControllersTableItem controller={controller} key={controller.serial}/>
            )}
            </tbody>
        </table>
    );
});

export default ControllersListTable;