import React from 'react';

const ControlPage = React.forwardRef((props, ref) => {
    return (

        <div className="page-wrapper">
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Dashboard
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
});

export default ControlPage;