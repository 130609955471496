import React from 'react';
import Logo from "../components/Logo";
import LoginForm from "../components/forms/LoginForm";


const LoginPage = React.forwardRef((props, ref) => {
    return (
        <div className="page page-center">
            <div className="container container-tight py-4">
                <Logo/>
                <div className="card card-md">
                    <div className="card-body">
                        <h2 className="h2 text-center mb-4">Login to your account</h2>
                        <LoginForm/>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default LoginPage;
