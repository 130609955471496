import React, {useContext, useState} from 'react';
import Label from "../UI/Label";
import Input from "../UI/Input";
import Button from "../UI/Button.tsx";
import axios from "axios";
import {AuthContext} from "../../utils/Context";



const LoginForm = React.forwardRef((props, ref) => {
    const { setIsAuth} = useContext(AuthContext);
    const [login, setLogin] = useState({email: '', password: ''})

    const https = require('https')

    const handleSubmit=(event) => {
        event.preventDefault();

        axios.post(`http://api.ringer.fun/v1/login`, {email:login.email, password:login.password}, {httpsAgent:new https.Agent({
                rejectUnauthorized: false,
            }) })
            .then(res => {
                console.log(res);
                console.log(res.data);

                if(res.status === 200){
                    setIsAuth(true);
                    localStorage.setItem('auth', 'true')
                    localStorage.setItem('token', res.data.token)

                }
            }).catch(err => console.log(err))
    }

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="mb-3">
                <Label>Email address</Label>
                <Input
                    type="email"
                    value={login.email}
                    onChange={event => setLogin({...login, email: event.target.value})}
                    placeholder="your@email.com"/>
            </div>
            <div className="mb-2">
                <Label>Password
                    <span className="form-label-description">
                    {/*<a href="./forgot-password.html">I forgot password</a>*/}
                  </span>
                </Label>
                <Input
                    type="password"
                    value={login.password}
                    onChange={event => setLogin({...login, password: event.target.value})}
                    placeholder="Your password"/>
            </div>
            <div className="form-footer">
                <Button className="btn-primary w-100" onClick={handleSubmit}>Sign in</Button>
            </div>
        </form>
    );
});

export default LoginForm;