import React from 'react';

const HelpPage = React.forwardRef((props, ref) => {
    return (

        <div className="page page-center">
            <h1>Help</h1>
        </div>
    );
});

export default HelpPage;