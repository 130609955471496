import React from 'react';

const AboutPage = React.forwardRef((props, ref) => {
    return (

        <div className="page page-center">
            <h1>ABOUT</h1>
        </div>
    );
});

export default AboutPage;