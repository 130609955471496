import React from 'react';


const Button = React.forwardRef((props, ref) => {

    let {
        className,
        ...otherProps
    } = props;

    let classNames = ['btn', className].join(' ');

    return (
        <button className={classNames} ref={ref}  {...otherProps}/>
    );
});

export default Button;