import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../utils/Context";
import { Link } from 'react-router-dom';
import axios from "axios";

const Navbar = () => {
    const { setIsAuth} = useContext(AuthContext);

    const logout = () => {
         setIsAuth(false);
         localStorage.removeItem('auth')
    }

    const [userinfo, setUserInfo] = useState({
        id: 0,
        email: "",
        first_name: "user",
        last_name: "name",
        active: true,
        role: "unknown",
        created_at: "",
        updated_at: ""
    })

    const [ setLoading] = useState(true);

    const token = localStorage.getItem('token');


    useEffect(() => {
        axios.get(`http://api.ringer.fun/v1/user`, {headers: { Authorization: `Bearer ${token}` }}, )
            .then(res => {
                console.log(res);
                console.log(res.data);

                if(res.status === 200){
                    setUserInfo(res.data);

                } else setUserInfo(
                    userinfo
                )
                setLoading(false);

            }).catch(err => console.log(err))
    }, [token, setLoading])

    return (
                <header className="navbar navbar-expand-md d-print-none">
                    <div className="container-xl">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu"
                                aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                            <Link to="/">
                                <img src="http://cloud.ringer.fun/static/logo.svg" width="110" height="32" alt="Ringer"
                                     className="navbar-brand-image"/>
                            </Link>
                        </h1>
                        <div className="navbar-nav flex-row order-md-last">
                            <div className="nav-item dropdown">
                                <Link to="/"  className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                                      aria-label="Open user menu">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round"
                                         className=" icon-tabler icons-tabler-outline icon-tabler-user-circle">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                                        <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
                                        <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"/>
                                    </svg>
                                    <div className="d-none d-xl-block ps-2">
                                        <div>{userinfo.first_name} {userinfo.last_name}</div>
                                        <div className="mt-1 small text-secondary">{userinfo.role}</div>
                                    </div>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                    {/*<Link to="/profile" className="dropdown-item">Profile</Link>*/}
                                    {/*<Link to="/feedback" className="dropdown-item">Feedback</Link>*/}
                                    {/*<div className="dropdown-divider"></div>*/}
                                    <Link to="/login" onClick={logout} className="dropdown-item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                                             strokeLinecap="round" strokeLinejoin="round"
                                             className="icon icon-tabler icons-tabler-outline icon-tabler-logout">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path
                                                d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"/>
                                            <path d="M9 12h12l-3 -3"/>
                                            <path d="M18 15l3 -3"/>
                                        </svg>
                                        Logout</Link>
                                </div>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse" id="navbar-menu">
                            <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
                                <ul className="navbar-nav">
                                    <li className="nav-item active">
                                        <Link to="/" className="nav-link">
                                            <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z"
                                                                                fill="none"></path><path
                            d="M5 12l-2 0l9 -9l9 9l-2 0"></path><path
                            d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path><path
                            d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path></svg>
                    </span>
                                            <span className="nav-link-title">
                      Home
                    </span>
                                        </Link>

                                    </li>

                    {/*                <li className="nav-item">*/}
                    {/*                    <Link className="nav-link" to="/products">*/}
                    {/*<span className="nav-link-icon d-md-none d-lg-inline-block">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"*/}
                    {/*         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"*/}
                    {/*         strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z"*/}
                    {/*                                                            fill="none"></path><path*/}
                    {/*        d="M9 11l3 3l8 -8"></path><path*/}
                    {/*        d="M20 12v6a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h9"></path></svg>*/}
                    {/*</span>*/}
                    {/*                        <span className="nav-link-title">*/}
                    {/*  Products*/}
                    {/*</span>*/}
                    {/*                    </Link>*/}
                    {/*                </li>*/}

                    {/*                <li className="nav-item">*/}
                    {/*                    <Link className="nav-link" to="/controllers">*/}
                    {/*<span className="nav-link-icon d-md-none d-lg-inline-block">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"*/}
                    {/*         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"*/}
                    {/*         strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z"*/}
                    {/*                                                            fill="none"></path><path*/}
                    {/*        d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7"></path><path*/}
                    {/*        d="M10 10l.01 0"></path><path d="M14 10l.01 0"></path><path*/}
                    {/*        d="M10 14a3.5 3.5 0 0 0 4 0"></path></svg>*/}
                    {/*</span>*/}
                    {/*                        <span className="nav-link-title">*/}
                    {/*  Control*/}
                    {/*</span>*/}
                    {/*                    </Link>*/}
                    {/*                </li>*/}
                    {/*                <li className="nav-item ">*/}
                    {/*                    <Link className="nav-link " to="/help">*/}
                    {/*<span className="nav-link-icon d-md-none d-lg-inline-block">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"*/}
                    {/*         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"*/}
                    {/*         strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z"*/}
                    {/*                                                            fill="none"></path><path*/}
                    {/*        d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path><path*/}
                    {/*        d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path><path d="M15 15l3.35 3.35"></path><path*/}
                    {/*        d="M9 15l-3.35 3.35"></path><path d="M5.65 5.65l3.35 3.35"></path><path*/}
                    {/*        d="M18.35 5.65l-3.35 3.35"></path></svg>*/}
                    {/*</span>*/}
                    {/*                        <span className="nav-link-title">*/}
                    {/*  Help*/}
                    {/*</span>*/}
                    {/*                    </Link>*/}
                    {/*                </li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
    );
};

export default Navbar;