import React from 'react';


const Logo = React.forwardRef((props, ref) => {
    return (
        <div className="text-center mb-4">
        <a href="." className="navbar-brand navbar-brand-autodark">
            <img src="./static/logo.svg" alt="ringer"
                 className="navbar-brand-image"/>
        </a>
        </div>
    );
});

export default Logo;