import React, {useState} from 'react';
import Footer from "../components/UI/Footer";
import ControllersListTable from "../components/tables/controllers-list/ControllersListTable";
import PageHeader from "./PageHeader";

const HomePage = React.forwardRef((props, ref) => {
    const [user_info] = useState(0)
    //const user_info = this.state.userInfo;
    console.log(user_info);
    return (
        <div className="page-wrapper">
            <PageHeader name="Dashboard"/>
            <div className="page-body">
                <div className="container-xl">
                    <div className="alert alert-info bg-light" role="alert">
                        <div className="d-flex">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon alert-icon" width="24"
                                     height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                    <path d="M12 9h.01"></path>
                                    <path d="M11 12h1v4h1"></path>
                                </svg>
                            </div>
                            <div>
                                To remotely manage the system, please select the desired controller from the list.
                            </div>
                        </div>
                    </div>
                    <div className="row row-deck row-cards">
                        <div className="col-md-12 col-lg-8">
                        <div className="card ">
                            <div className="card-status-top bg-green"></div>
                            <div className="card-header ">
                                <h3>List of ontrollers</h3>
                            </div>
                            <div className="card-table table-responsive">
                                <ControllersListTable/>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="card">
                                <div className="card-stamp">
                                    <div className="card-stamp-icon bg-yellow">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                                d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                                            <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                                        </svg>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h3 className="card-title">Remote control</h3>
                                    <p className="text-secondary">To remotely manage the system, please select the desired controller from the list on the left.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
});

export default HomePage;