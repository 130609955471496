import React from 'react';


const Input = React.forwardRef((props, ref) => {
    return (
        <input className="form-control" ref={ref}  {...props}/>

    );
});

export default Input;