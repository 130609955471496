import React from 'react';


const PageHeader = React.forwardRef((props, ref) => {
    return (
        <div className="page-header d-print-none" ref={ref}>
            <div className="container-xl">
                <div className="row g-2 align-items-center">
                    <div className="col">
                        <div className="page-pretitle">
                            Overview
                        </div>
                        <h2 className="page-title">
                            {props.name}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default PageHeader;