import React from 'react';

const ProductsPage = React.forwardRef((props, ref) => {
    return (

        <div className="page page-center">
            <h1>Products</h1>
        </div>
    );
});

export default ProductsPage;